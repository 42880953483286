import React, { useState } from 'react';

import { Cascader, Space, Tooltip } from 'antd';
import type { DefaultOptionType } from 'antd/es/cascader';

import { escapeRegExp, isEmpty, isNumber, isString, truncate } from 'lodash';
import { useDashboardContext } from '../hooks';
import { FilterOptions, Settings } from '../types/dashboardTypes';
import { BooleanExpression, ParsedExpression } from '../types/expressionsDslTypes';


export const MultiFilter: React.FC<{
  viewFilters: Settings["filters"],
  filterOptions: FilterOptions | undefined ,
  filterOptionsLoading: boolean
}> = ({ viewFilters, filterOptions, filterOptionsLoading }) => {
  const { dispatch } = useDashboardContext();
  const [ error, setError ] = useState<string | undefined>(undefined);

  interface Option {
    key: string | number;
    value: string | number;
    label: string | number | JSX.Element;
    children?: Option[];
    parent?: string;
    parentDisplayName?: string;
  }

  type SelectedValues = (string|number)[][];

  const options = Object.keys(viewFilters).reduce((acc: Option[], fieldName: string) => {
    const fieldMetadata = viewFilters[fieldName];

    if (filterOptions && fieldName in filterOptions) {
      // Remove string values that are case-insensitive duplicates
      const childVals = filterOptions[fieldName].reduce((ary: (string|number)[], item) => {
        if (!isString(item) || (isString(item) && !ary.find((opt) => (opt.toString()).toLowerCase() === item.toLowerCase()))) {
          ary.push(item);
        }
          return ary;
      }, []);

      acc.push({
        value: fieldName,
        label: fieldMetadata.display_name,
        key: fieldName,
        children: childVals.map((entry) => ({
          key: entry,
          value: entry,
          label: isString(entry) ? (<Tooltip title={entry}>{truncate(entry, { length: 30 })}</Tooltip>) : entry,
          parent: fieldName,
          parentDisplayName: fieldMetadata.display_name
        })),
      });
    }
    return acc;
  }, []);

  const filtersToExpression = (values: SelectedValues): ParsedExpression | undefined => {
    const groupedFilters = values.reduce((acc, cur) => {
      const updated = { ...acc };
      if (!isString(cur[0])) {
        throw new Error("Invalid filter key");
      }
      if (!Object.keys(acc).includes(cur[0])) {
        updated[cur[0]] = [];
      }
      updated[cur[0]].push(cur[1]);
      return updated;
    }, {} as {[key: string]: (string|number)[]});

    const expressions: BooleanExpression[] = Object.keys(groupedFilters).map((key) => {
      const filterValues = groupedFilters[key];
      if (filterValues.length === 0) {
        throw new Error("Invalid filter value");
      }

      if (isNumber(filterValues[0])) {
        const exprAry = filterValues.map((val) => ({
          expr: {
            op_id: "EQ",
            lhs: {
              expr: {
                field_id: "metadata_numeric_value",
                metadata_key: key
              }
            },
            rhs: {
              expr: val
            }
          }
        } as BooleanExpression));

        return {
          expr: {
            op_id: "ANY",
            expressions: exprAry,
          }
        }
      } else {
        // Construct a string that is python-parseable regex
        let regex = groupedFilters[key].map((val) =>  {
          if (!isString(val)) {
            throw new Error("Invalid filter value");
          }
          return escapeRegExp(val);
        }).join("|");
        // Make the regex case-insensitive
        regex = `(?i)${regex}`;

        return {
          expr: {
            op_id: "MATCHES",
            lhs: {
              expr: {
                field_id: "metadata_value",
                metadata_key: key
              }
            },
            rhs: {
              expr: regex
            }
          }
        };
      }
    });

    const filterExpression: ParsedExpression = {
      version: 1,
      parsed: {
        expr: { op_id: "ALL", expressions }
      }
    }
    return isEmpty(expressions) ? undefined : filterExpression;
  };

  const onChange = (values: SelectedValues) => {
    setError(undefined);
    // Filter out the parent values
    const selectedOptions = values.filter((value) => value.length > 1);

    const MAX_FILTERS = 10;
    if (selectedOptions.length > MAX_FILTERS) {
      setError(`Please select ${MAX_FILTERS} or fewer filters`);
      return;
    }
    const formattedFilters = filtersToExpression(selectedOptions);
    dispatch({ type: "SET_FILTERS", payload: formattedFilters });
  };

  const renderSelectedFilters = (labels: string[], selectedFields: DefaultOptionType[] | undefined) => {
    if (!selectedFields || selectedFields.length === 0) {
      return;
    }

    const option = selectedFields[selectedFields.length - 1];
    const label = labels[labels.length - 1];
    if (selectedFields.length > 1 && option.parent) {
      return <span key={option.value}>{option.parentDisplayName}: {label}</span>;
    } else {
      return <span key={option.value}>{option.label}: All</span>;
    }
  }

  return (
    <Space>
      <Cascader
        dropdownClassName="dashboard-multi-filter-cascader"
        style={{ minWidth: 140, width: "100%" }}
        placeholder="Filter"
        options={options}
        onChange={onChange}
        displayRender={renderSelectedFilters}
        multiple
        status={error ? "error" : undefined}
        maxTagCount={7} // Selected options are truncated in the UI after maxTagCount items
        loading={filterOptionsLoading}
      />
      {error && <span style={{ color: "red" }}>{error}</span>}
    </Space>
  );
}
